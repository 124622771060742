import { defineStore } from 'pinia';

interface Error {
  active: boolean;
  message: string | null;
}

export const useError = defineStore('error', {
  state: (): Error => {
    return {
      active: false,
      message: null,
    };
  },

  actions: {
    update(message: string) {
      this.message = message;
      this.active = true;

      return this;
    },

    reset() {
      this.message = null;
      this.active = false;
    },
  },
});
